import React from 'react';
import { HeadFC } from 'gatsby';

import Contact from '../components/pages/Contact';
import Seo from '../components/Seo';

const ContactPage = () => {
  return <Contact />;
};

export const Head: HeadFC = () => <Seo title="Contato" canonical="/contato/" />;

export default ContactPage;
