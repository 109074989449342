import React from 'react';

import Layout from '../../Layout';
import { ContactForm } from '../shared/ContactForm';

function Contact() {
  return (
    <Layout>
      <ContactForm tag="h1" />
    </Layout>
  );
}

export default Contact;
